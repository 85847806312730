import { defineComponent } from 'vue';
import ApiService from '@/services/api';
import VertButton from '@/components/VertButton.vue';
import VertInput from '@/components/VertInput.vue';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Slider from 'primevue/slider';
import Paginator from 'primevue/paginator';

export default defineComponent({
  name: 'VertDataTable',
  emits: ['ready', 'pageChanged'],
  props: ['loading', 'tiposEmissao', 'tiposTitulo', 'clientes'],
  components: {
    VertInput,
    Dropdown,
    DataTable,
    Column,
    Slider,
    Calendar,
    VertButton,
    Paginator
  },

  async mounted() {
    await this.carregarItems();
    this.$emit('ready');
  },

  methods: {
    async abrirSerie(serie: any, emission: any) {
      const emissionId = emission.id;
      const serieId = serie.series_id;

      await this.$router.push({
        name: 'Details',
        params: { emissionId, serieId }
      });

      this.$emit('pageChanged');
    },

    limparFiltros() {
      this.dataVecimento = [];
      this.dataEmissao = [];
      this.tipoEmissao = '';
      this.tipoTitulo = '';
      this.cliente = '';
      this.order = '-date';
      this.carregarItems();
    },

    ordenar(params: any) {
      if (params.sortField == '_date') {
        this.order = params.sortOrder == 1 ? 'date' : '-date';
        this.carregarItems();
        return;
      }

      if (params.sortField == 'type') {
        this.items = [];
        this.order = params.sortOrder == 1 ? 'type' : '-type';
        this.carregarItems();
        return;
      }
    },

    onChangeSearchText(value: string) {
      this.searchText = value;
      this.carregarItems();
    },

    async carregarItems(termo = '', page = 1) {
      this.items = [];
      this.searching = true;
      const api = new ApiService();

      const { ok, result } = await api
        .getEmissions(
          page,
          this.order,
          this.tipoTitulo,
          this.tipoEmissao,
          this.dataEmissao,
          this.dataVecimento,
          this.cliente,
          this.searchText
        )
        .then((result) => ({ ok: true, result }))
        .catch((err) => {
          console.log(err);
          return {
            ok: false,
            result: null
          };
        });

      if (!ok) {
        this.searching = false;
        this.$toast.add({
          severity: 'error',
          summary: 'Tente Novamente',
          detail: 'Não foi possível completar a ação.',
          life: 3000
        });
      }

      this.currentPage = result!.page;
      this.totalItems = result!.count;
      this.totalPages = result!.num_pages;

      (this.items as any) = result!.results.map((i: any) => {
        const date = i.date.split('/').reverse().join('-');
        i._date = new Date(date + 'T00:00:00');
        return i;
      });

      this.searching = false;

      if (this.items.length == 1) {
        this.$nextTick(() => {
          (document.querySelector('.p-datatable-tbody .p-row-toggler.p-link') as any).click();
        });
      }
    }
  },

  watch: {
    async currentPage() {
      await this.carregarItems('', this.currentPage);
      this.$emit('pageChanged');

      const mtab = document.querySelector('#mtab');
      (this as any).$smoothScroll({
        updateHistory: false,
        scrollTo: mtab,
        offset: -24
      });
    }
  },

  computed: {
    de() {
      if (this.totalItems === 0) return 0;
      const de = 10 * (this.currentPage - 1) + 1;
      return isNaN(de) ? 0 : de;
    },

    ate() {
      const value = 10 * this.currentPage;
      const ate = value > this.totalItems ? this.totalItems : value;
      return isNaN(ate) ? 0 : ate;
    }
  },

  data() {
    const formatter = new Intl.NumberFormat('pt-br', {
      style: 'currency',
      currency: 'BRL'
    });

    return {
      currency: formatter,
      order: '-date',
      dataEmissao: [],
      dataVecimento: [],
      tipoEmissao: '',
      tipoTitulo: '',
      cliente: '',
      showFilters: false,
      searchText: '',
      searching: false,
      currentPage: 1,
      totalPages: 0,
      totalItems: 0,
      expandedRows: [],
      items: []
    };
  }
});
