import App from './App.vue';
import Store from './services/store';
import Router from './services/router';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import VueSmoothScroll from 'vue3-smooth-scroll';
import { createApp } from 'vue';
import LinkWithTrigger from './components/LinkWithTrigger.vue';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'aos/dist/aos.css';
import 'animate.css';

createApp(App)
  .use(Store)
  .use(Router)
  .use(PrimeVue)
  .use(ToastService)
  .use(VueSmoothScroll)
  .component('LinkWithTrigger', LinkWithTrigger)
  .directive('tooltip', Tooltip)
  .mount('#app');
