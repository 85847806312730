import {
  VertMetricsResponse,
  VertMetrics,
  ChartResponse,
  ChartData,
  PublicResponse
} from '@/models/home';
import axios from 'axios';

export interface ISurveyPayload {
  ip: string | null;
  rating: string | number | null;
  experience_description: string | null;
  browser_information: string;
  user: string;
  emission: string | number;
  series: string | number;
}

export default class ApiService {
  constructor() {
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    this.publicUrl = process.env.VUE_APP_PUBLIC_URL;
    this.baseUrlV1 = `${this.baseUrl}v1/`;
    this.baseUrlV2 = `${this.baseUrl}v2/`;
  }

  private baseUrl = '';
  private publicUrl = '';
  private baseUrlV1 = '';
  private baseUrlV2 = '';

  public getPuDownloadLink(serieId: string): string {
    return this.publicUrl + `series/${serieId}/export/calc/memory/`;
  }

  public async subscribeNewsletter(
    name: string,
    email: string,
    series_id: string,
    emission_id: string,
    token: string
  ): Promise<any> {
    const url = this.baseUrlV1 + 'maillist/';
    const res = await axios.post<PublicResponse<any>>(url, {
      name,
      email,
      series_id,
      emission_id,
      token
    });
    return res.data ?? {};
  }

  public async getPUList(serieId: string, page = 1): Promise<any> {
    const url = this.baseUrlV2 + `series/${serieId}/unit_prices?page=${page}&page_size=10`;
    const res = await axios.get<PublicResponse<any>>(url);
    return res.data ?? {};
  }

  public async getEventList(serieId: string): Promise<any> {
    const url = this.baseUrlV1 + `series/${serieId}/event-list`;
    const res = await axios.get<PublicResponse<any>>(url);
    return res.data.results ?? [];
  }

  public async getSeriesRating(serieId: string, page = 1): Promise<any> {
    const url = this.baseUrlV1 + `series/${serieId}/series-rating?page=${page}&page_size=10`;
    const res = await axios.get<PublicResponse<any>>(url);
    return res.data ?? {};
  }

  public async getPaymentEvents(serieId: string): Promise<any> {
    const url = this.baseUrlV1 + `series/${serieId}/payment-event/union?page_size=9999`;
    const res = await axios.get<PublicResponse<any>>(url);
    return res.data.results ?? [];
  }

  public async getActiveRatingLevel(serieId: string): Promise<any> {
    const url = this.baseUrlV1 + `series/${serieId}/active-rating-level`;
    const res = await axios.get<PublicResponse<any>>(url);
    const data = res.data.results;

    return data.length > 0 ? data[0].scale : 'Não há rating para esta série';
  }

  public async getParticipants(emissionId: string): Promise<any> {
    const url = this.baseUrlV1 + `emission/${emissionId}/participants`;
    const res = await axios.get<Array<any>>(url);

    let debtor = '',
      trustee = '',
      lead_coordinator = '';
    if (res.data.length) {
      debtor = res.data[0].debtor;
      trustee = res.data[0].trustee;
      lead_coordinator = res.data[0].lead_coordinator;

      lead_coordinator = !lead_coordinator?.length ? '' : lead_coordinator[0];
      trustee = !trustee?.length ? '' : trustee[0];
      debtor = !debtor?.length ? '' : debtor[0];
    }

    return { debtor, trustee, lead_coordinator };
  }

  public async getSerie(serieId: string): Promise<any> {
    const url = this.baseUrlV1 + `series/${serieId}`;
    const res = await axios.get<Array<any>>(url);
    return res.data ?? {};
  }

  public async getEmission(emissionId: string): Promise<any> {
    const url = this.baseUrlV1 + `emission/${emissionId}`;
    const res = await axios.get<Array<any>>(url);
    return res.data ?? {};
  }

  public async getLastUnitPrice(serieId: string, date: string): Promise<any> {
    const url = this.baseUrlV2 + `series/${serieId}/last-unit-price/?date=${date}`;
    const res = await axios.get<Array<any>>(url);
    return res.data.length ? res.data[0] : {};
  }

  public async getEmissions(
    page: number,
    order: string,
    type = '',
    concentration = '',
    dataEmissao = [],
    dataVecimento = [],
    cliente = '',
    termo = '',
    size = 10
  ): Promise<PublicResponse<any>> {
    let params = '';
    if (!!type) params += '&type=' + type;
    if (!!concentration) params += '&concentration=' + concentration.toLowerCase();
    if (!!cliente) params += '&originator_name=' + cliente;
    if (!!termo) params += '&q=' + termo;

    if (dataEmissao?.length == 2) {
      const start = (dataEmissao[0] as any)?.toLocaleDateString('pt-br') ?? '';
      const end = (dataEmissao[1] as any)?.toLocaleDateString('pt-br') ?? '';

      if (!!start) params += '&date_after=' + start;
      if (!!end) params += '&date_before=' + end;
    }

    if (dataVecimento?.length == 2) {
      const start = (dataVecimento[0] as any)?.toLocaleDateString('pt-br') ?? '';
      const end = (dataVecimento[1] as any)?.toLocaleDateString('pt-br') ?? '';

      if (!!start) params += '&due_date_after=' + start;
      if (!!end) params += '&due_date_before=' + end;
    }

    const url =
      this.baseUrlV1 + `emission/?page=${page}${params}&page_size=${size}&ordering=${order}`;
    const res = await axios.get<PublicResponse<any>>(url);
    return res.data;
  }

  public async getVertMetrics(): Promise<VertMetrics> {
    const url = this.baseUrlV1 + 'vert-metrics';
    const res = await axios.get<VertMetricsResponse>(url);
    return new VertMetrics(res.data);
  }

  public async getChartData(): Promise<ChartData> {
    const url = this.baseUrlV1 + 'dashboard';
    const res = await axios.get<ChartResponse>(url);

    const data = new ChartData();
    for (const key of Object.entries(res.data.result)
      .map((i) => i[0])
      .sort()) {
      if (key == 'totals') continue;

      const element = res.data.result[key];
      data.legends.push(key);

      data.values.push(element.value);
      data.quantities.push(element.quantity);
      data.percentVolumes.push(element.percent_volume);
    }

    return data;
  }

  public async getFinancialTitles(): Promise<any[]> {
    const url = this.baseUrlV1 + 'financial-title';
    const res = await axios.get<any[]>(url);
    return res.data;
  }

  public async getCustomers(): Promise<any[]> {
    const url = this.baseUrlV1 + 'clients';
    const res = await axios.get<any[]>(url);
    return res.data;
  }

  public sendFeedbackSurvey(data: ISurveyPayload): Promise<any> {
    const url = this.baseUrlV1 + 'satisfaction-research/';
    return axios.post<ISurveyPayload>(url, data);
  }

  public async getDocumentsReport(
    emissionId: string,
    page = 1,
    pageSize = 10,
    order: string
  ): Promise<any> {
    const url =
      this.baseUrlV1 +
      `emission/${emissionId}/reporting-documents/?page=${page}&page_size=${pageSize}&ordering=${order}`;
    const res = await axios.get<PublicResponse<any>>(url);
    return res.data ?? {};
  }
}
