export class PublicResponse<T> {
  count = 0;
  page = 0;
  num_pages = 0;
  results: T[] = [];
}

export class VertMetricsResponse {
  public total_series = 0;
  public total_emission = 0;
  public total_emitted_volume = 0;
}

export class VertMetrics {
  constructor(response: VertMetricsResponse) {
    this.totalEmissoes = response.total_emitted_volume;
    this.quantidadeSeries = response.total_series;
    this.quantidadeEmissoes = response.total_emission;
  }

  public totalEmissoes = 0;
  public quantidadeSeries = 0;
  public quantidadeEmissoes = 0;
}

export class ChartResponse {
  public result: ChartInnerResponse = new ChartInnerResponse();
}

export class ChartItemResponse {
  public value = 0;
  public quantity = 0;
  public percent_volume = 0;
}

export class ChartInnerResponse {
  [key: string]: ChartItemResponse;
  public 'Debêntures Financeiras': ChartItemResponse = new ChartItemResponse();
  public 'CRA': ChartItemResponse = new ChartItemResponse();
  public 'CRI': ChartItemResponse = new ChartItemResponse();
  public 'Debêntures Imobiliárias': ChartItemResponse = new ChartItemResponse();
  public 'Debêntures': ChartItemResponse = new ChartItemResponse();
}

export class ChartData {
  constructor() {
    this.values = [];
    this.quantities = [];
    this.percentVolumes = [];
    this.legends = [];
  }

  public values: number[];
  public quantities: number[];
  public percentVolumes: number[];
  public legends: string[];
}
