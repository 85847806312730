import { createStore } from 'vuex';

const store = createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {}
});

export default store;
